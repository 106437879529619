<template>
  <div>
    <img src="../assets/images/logo-Gemgloo-04.png" alt="Gemgloo"
     class="rounded-circle logo-gemgloo fixed-top mt-3 ml-4">
    <img src="../assets/images/top-wave.png" class="wave" alt="wave">
    <span class="text-white h4 on-wave">Sistema para Clinica</span>
    <div class="container-fluid">
      <div class="row" style="padding-top:20vh">
        <div class="col-md-6 mt-5 px-5">
          <img src="../assets/images/Healtcare-01.png" alt="Clinica">
        </div>
        <div class="col-md-4 mt-5 px-5">
          <form @submit.prevent="login"
           class="card border border-gray px-4 pb-3 pt-5">
            <h1 class="title-neon80 text-cyan-light text-center">Inicio de Sesión</h1>
            <div class="inputBox mt-5">
              <input type="email" name="email" required v-model="email" value=""
               onkeyup="this.setAttribute('value', this.value);"
               class="pb-1 h5" autocomplete="off">
              <label>Correo Electronico</label>
            </div>
            <div class="inputBox mt-3">
              <input type="password" name="password" required v-model="password" value=""
                onkeyup="this.setAttribute('value', this.value);"
                class="pb-1 h5" autocomplete="off">
              <label>Contraseña</label>
            </div>
            <b-alert v-model="showAlert" class="pr-0" variant="danger" dismissible>
              {{validationError}}
            </b-alert>
            <div class="row mt-3 mb-3">
              <div class="col-md-3"></div>
              <button type="submit" class="col-md-5 btn btn-gradient text-white">Entrar</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 pt-5 text-center text-cyan-light h5">
        {{getCopyRightYear()}} &copy; Developed by Gemgloo
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'index',
  data() {
    return {
      email: null,
      password: null,
      showAlert: false,
      validationError: null,
    };
  },
  methods:
  {
    async login() {
      /* localStorage.setItem('user-token', true);
      window.location.href = 'dashboard'; */
      const credenciales = { email: this.email, password: this.password };
      try {
        const response = await axios.post(`${process.env.VUE_APP_BASE_API}/v2/login`, credenciales, { headers: { 'content-type': 'application/json' } });
        if (response.status === 200) {
          // eslint-disable-next-line camelcase
          const { access_token } = response.data.data;
          localStorage.setItem('user-token', access_token);
          window.location.href = 'dashboard';
        } else {
          console.log('estado: ', response.status);
        }
      } catch (error) {
        localStorage.removeItem('user-token');
        this.showAlert = true;
        this.validationError = error.response.data.message;
      }
    },
    getCopyRightYear() {
      return new Date().getFullYear();
    },
  },
};
</script>

<style scoped>
@font-face {
  font-family: 'Neon 80s';
  src: url('~@/assets/fonts/Neon.ttf') format('truetype');
}
.inputBox {
  position: relative;
}
.inputBox input {
  width: 100%;
  padding: 0.625rem 0;
  font-size: 1rem;
  color:#50b6ba;
  letter-spacing: 0.062rem;
  margin-bottom: 1.875rem;
  border: none;
  border-bottom: 2px solid #52b4b7;
  outline: none;
  background: transparent;
}
.inputBox label {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0.625rem 0;
  font-size: 1rem;
  color: #50b6ba;
  pointer-events: none;
  transition: 0.5s;
}
.inputBox input:focus ~ label,
.inputBox input:valid ~ label,
.inputBox input:not([value='']) ~ label {
  top: -1.525rem;
  left: 0;
  color: #50b6ba;
  font-size: 0.75rem;
}
.wave
{
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.on-wave{
  position: fixed;
  z-index: -1;
  top: 35px;
  right: 35px;
}
.logo-gemgloo
{
  width: 75px;
  height: 75px;
}
.title-neon80
{
  font-family:'Neon 80s';
}
.text-cyan-light
{
 color:#50b6ba;
}
.border-gray
{
  border-color: #959595 !important;
}
.btn-gradient
{
  background-image: linear-gradient(to right,#7dd394 0%, #66d69e 51%, #54d8a7 100%);
  border-radius: 15px 15px;
}
</style>
